<template>
    <div
        class="gst-review-purchase-modal d-flex flex-column u-height-100 overflow-y-hidden"
        :class="{
            'rounded-t-1': $vuetify.breakpoint.mdAndUp,
            'rounded-t-lg': !$vuetify.breakpoint.mdAndUp,
        }"
        color="text">
        <HeaderVariant1
            class="flex-grow-0 gst-review-purchase-modal__header"
            :title="$t( 'title' )"
            @close="$emit('close')" />
        <div
            class="gst-review-purchase-modal__content d-flex flex-column u-height-100 pa-0 pa-md-4">
            <EventReviewPurchaseModalBody
                class="pa-2 pa-md-0"
                :event="event"
                :selected-hotel-reservation="selectedHotelReservationItem"
                :selected-ticket="selectedTicket"
                :tickets-quantity="ticketsQuantity"
                :event-tabs="eventTabs"
                @change-event-ticket="onChangeEventTicketDo"
                @change-selected-hotel="onChangeSelectedHotelDo"
                @remove-selected-hotel="onRemoveSelectedHotelDo"
                @change-tab="onChangeTabDo" />
            <EventReviewPurchaseModalFooter
                class="mt-0 mt-md-2"
                :title="footerTitle"
                :event="event"
                :bundle-products="bundleProductsItems" />
            <EventReviewPurchaseModalButton
                class="flex-grow-0 mt-0 mt-md-2"
                :placeholder="submitButtonPlaceholder"
                :test-id-options="submitButtonTestId"
                :disabled="false"
                :color="!hasSelectedTicket || !hasSelectedHotel ? 'primary' : 'success'"
                @submit="onSubmitButtonDo" />
        </div>
    </div>
</template>

<script>
    import CloseModalOnRouteChangeMixin from '@core/mixins/modals/CloseModalOnRouteChange';
    import CloseModalOnBreakpointChangeMixin from '@core/mixins/modals/CloseModalOnBreakpointChange';
    import HeaderVariant1 from '@tenant/app/components/modals/HeaderVariant1';
    import { create as CreateBundleProductsModel } from '@tenant/app/modelsV2/BundleProductsModel';
    import { create as CreateBundleProductsHotelReservationModel } from '@tenant/app/modelsV2/BundleProductsHotelReservationModel';
    import EventReviewPurchaseModalBody from './EventReviewPurchaseModal/EventReviewPurchaseModalBody';
    import EventReviewPurchaseModalButton from './EventReviewPurchaseModal/EventReviewPurchaseModalButton';
    import EventReviewPurchaseModalFooter from './EventReviewPurchaseModal/EventReviewPurchaseModalFooter';

    export default {
        name: 'EventReviewPurchaseModal',
        components: {
            HeaderVariant1,
            EventReviewPurchaseModalBody,
            EventReviewPurchaseModalButton,
            EventReviewPurchaseModalFooter
        },
        mixins: [ CloseModalOnRouteChangeMixin, CloseModalOnBreakpointChangeMixin ],
        props: {
            event: {
                type: Object,
                required: true,
            },
            selectedHotelReservation: {
                type: Object,
                default: null,
            },
            selectedTicket: {
                type: Object,
                default: null
            },
            busEventParent: {
                type: Object,
                default: null
            },
            ticketsQuantity: {
                type: Number,
                default: 2
            },
            bundleProducts: {
                type: Object,
                default: function ( ) {
                    return CreateBundleProductsModel( );
                }
            },
            eventTabs: {
                type: Object,
                required: true
            },
            footerTitle: {
                type: String,
                default: null
            }
        },
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.eventV2.theEvent._components.eventReviewPurchaseModal'
        },
        testIdOptions: {
            keyPrefix: 'event.eventContent.EventReviewPurchaseModal'
        },
        data( ) {
            return {
                selectedHotelReservationItem: this.selectedHotelReservation,
                bundleProductsItems: this.bundleProducts
            };
        },
        computed: {
            hasSelectedHotel( ) {
                return this.selectedHotelReservationItem !== null;
            },

            hasSelectedTicket( ) {
                const quantity = this.bundleProducts.getQuantity( );
                return quantity.tickets > 0;
            },
            submitButtonPlaceholder( ) {
                if ( this.hasSelectedTicket && this.hasSelectedHotel ) {
                    return this.$t( 'buttons.continueToCheckout' );
                } else if ( !this.hasSelectedTicket ) {
                    return this.$t( 'buttons.addTickets' ); 
                }

                return this.$t( 'buttons.addHotel' );
            },
            submitButtonTestId( ) {
                if ( this.hasSelectedTicket ) {
                    return this.$testId( 'buttons.continueToCheckout' );
                }

                return this.$testId( 'buttons.addTickets' );
            }
        },
        methods: {
            onSubmitButtonDo( ) {
                if ( !this.hasSelectedTicket ) {
                    this.busEventParent.$emit( 'change-tab', this.eventTabs.TICKETS );
                } else if ( !this.hasSelectedHotel ) {
                    this.busEventParent.$emit( 'change-tab', this.eventTabs.HOTELS );
                }
                else {
                    this.busEventParent.$emit( 'go-to-checkout' );
                }

                this.$emit( 'close' );
            },
            onChangeEventTicketDo( ) {
                this.busEventParent.$emit( 'change-event-ticket' );
                this.$emit( 'close' );
            },
            onChangeSelectedHotelDo( ) {
                this.busEventParent.$emit( 'change-selected-hotel' );
                this.$emit( 'close' );
            },
            onRemoveSelectedHotelDo( ) {
                this.selectedHotelReservationItem = null;
                this.bundleProductsItems.setHotelReservation( CreateBundleProductsHotelReservationModel( ) );

                this.busEventParent.$emit( 'remove-selected-hotel' );
                if ( !this.hasSelectedTicket ) {
                    this.$emit( 'close' );
                }
            },
            onChangeTabDo( value ) {
                this.busEventParent.$emit( 'change-tab', value );
                this.$emit( 'close' );
            }
        }
    };
</script>

<style lang="scss" scoped>
@import "@scssVariables";
@import "@scssMixins";

.gst-review-purchase-modal {
    border-radius: 0 !important;

    .gst-review-purchase-modal__content {
        background: theme-color( 'white' );
    }
}
</style>