<template>
    <div
        class="gst-divider-plus u-position-relative d-flex align-center justify-center"
        :style="{ height: height }">
        <IconPlus class="gst-divider-plus__icon" />
    </div>
</template>

<script>
    import IconPlus from '@core/shared/assets/icons/plus.svg';

    export default {
        name: 'DividerPlus',
        components: {
            IconPlus
        },
        props: {
            height: {
                type: String,
                default: '8px'
            }
        }
    };
</script>

<style lang="scss">
@import "@scssVariables";
@import "@scssMixins";

.gst-divider-plus {
    .gst-divider-plus__icon {
        height: 20px;
        width: 20px;
        z-index: 2;

        .gst-svg-icon {
            fill: theme-color( 'primary' );
        }
    }
}

.gst-divider-plus::before,
.gst-divider-plus::after {
    position: absolute;
    content: "";
}

.gst-divider-plus::before {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: theme-color( 'white' );
    z-index: 2;
}

.gst-divider-plus::after {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 34px;
    width: 34px;
    border: 1px solid theme-color-hex( 'senary-darken-2' );
    background: theme-color( 'white' );
    border-radius: border-radius( 'circle' );
    transform: translate( -50%, -50% );
    z-index: 1;
}
</style>
