<template>
    <div class="gst-ticket-and-hotel-empty-card d-flex flex-column align-center justify-center">
        <div v-if="hasIcon" class="gst-ticket-and-hotel-empty-card__icon d-flex">
            <component :is="icon" />
        </div>
        <div class="gst-ticket-and-hotel-empty-card__content d-flex flex-wrap justify-center">
            <h6 v-if="title" class="gst-ticket-and-hotel-empty-card__content-title u-width-100 text-center">
                {{ title }}
            </h6>
        </div>
    </div>
</template>

<script>
    import IconTicketRound from '@tenant/app/assets/icons/ticket_round.svg';
    import IconHotelRound from '@tenant/app/assets/icons/hotel_round.svg';

    export default {
        name: 'TicketAndHotelEmptyCard',
        components: {
            /* eslint-disable vue/no-unused-components */
            IconTicketRound,
            IconHotelRound
            /* eslint-enable vue/no-unused-components */
        },
        props: {
            title: {
                type: String,
                default: null
            },
            showTicketIcon: {
                type: Boolean,
                default: false
            },
            showHotelIcon: {
                type: Boolean,
                default: false
            }
        },
        testIdOptions: {
            keyPrefix: 'event._components.eventContent._components.TicketAndHotelEmptyCard'
        },
        computed: {
            icon( ) {
                return this.showTicketIcon ? 'IconTicketRound' : 'IconHotelRound';
            },
            hasIcon( ) {
                return this.showTicketIcon || this.showHotelIcon;
            }
        }
    };
</script>

<style lang="scss">
    @import '@scssVariables';
    @import '@scssMixins';

    .gst-ticket-and-hotel-empty-card {
        padding: theme-spacing( 9 );
        border: 1px dashed theme-color( 'primary' );
        border-radius: border-radius( 'xs' );

        .gst-ticket-and-hotel-empty-card__icon {
            margin: theme-spacing( 2, 0 );

            .gst-svg-icon {
                fill: theme-color( 'primary' );
            }
        }

        .gst-ticket-and-hotel-empty-card__content {
            .gst-ticket-and-hotel-empty-card__content-title {
                line-height: line-height( 'xl' );
                font-weight: font-weight( 'large' );
            }

            .gst-ticket-and-hotel-empty-card__content-btn {
                line-height: line-height( 'xl' );
                margin-top: theme-spacing( 1 );
                background-color: theme-color( 'white' ) !important;
                color: theme-color( 'primary' ) !important;
                font-size: font-size( 's' );
                font-weight: font-weight( 'regular' );
                text-transform: none;
            }
        }
    }
</style>