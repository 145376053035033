<template>
    <BaseButton
        class="gst-review-purchase-modal-submit-btn gst-btn"
        block
        depressed
        :color="color"
        :disabled="disabled"
        :placeholder="placeholder"
        :data-test-id="$testId(testIdOptions)"
        @click="$emit('submit')" />
</template>

<script>
    import BaseButton from '@core/shared/components/buttons/BaseButton';

    export default {
        name: 'EventReviewPurchaseModalButton',
        components: {
            BaseButton
        },
        props: {
            color: {
                type: String,
                default: 'primary'
            },
            placeholder: {
                type: String,
                default: null
            },
            testIdOptions: {
                type: String,
                required: true
            },
            disabled: {
                type: Boolean,
                required: true
            }
        }
    };
</script>

<style lang="scss" scoped>
@import "@scssVariables";
@import "@scssMixins";

.gst-review-purchase-modal-submit-btn {
    height: theme-spacing( 11 ) !important;
    line-height: line-height( 'xxxl' );
    font-size: font-size( 'l' );
    font-weight: font-weight( 'large' );
    text-transform: none;
    border-radius: border-radius( 'none' );
}
</style>