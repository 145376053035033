<template>
    <div class="gst-review-purchase-modal-body flex-grow-1 content-max-width u-width-100">
        <SelectedEventTicketCard
            v-if="selectedTicket"
            :ticket="selectedTicket"
            :quantity="ticketsQuantity"
            :image-width="$vuetify.breakpoint.mdAndUp ? 124 : 98"
            @change="$emit( 'change-event-ticket' )" />
        <TicketAndHotelEmptyCard
            v-else
            class="mb-2"
            :title="$t( 'emptyStates.tickets.title' )"
            :show-ticket-icon="true" />
        <DividerPlus v-if="selectedTicket && selectedHotelReservation" />
        <SelectedHotelCard
            v-if="selectedHotelReservation"
            :selected-hotel-reservation="selectedHotelReservation"
            :event="event"
            :show-image="true"
            @change="$emit( 'change-selected-hotel' )" />
        <TicketAndHotelEmptyCard
            v-else
            class="mt-2"
            :title="$t( 'emptyStates.hotel.title' )"
            :button-placeholder="$t( 'emptyStates.hotel.button' )"
            :show-hotel-icon="true"
            @submit="$emit( 'change-tab', eventTabs.HOTELS )" />
    </div>
</template>

<script>
    import DividerPlus from '@tenant/app/components/misc/DividerPlus.vue';
    import SelectedEventTicketCard from '../_components/SelectedEventTicketCard';
    import SelectedHotelCard from '../_components/SelectedHotelCard';
    import TicketAndHotelEmptyCard from '../_components/TicketAndHotelEmptyCard';

    export default {
        name: 'EventReviewPurchaseModalBody',
        components: {
            DividerPlus,
            SelectedEventTicketCard,
            SelectedHotelCard,
            TicketAndHotelEmptyCard
        },
        props: {
            event: {
                type: Object,
                required: true,
            },
            selectedHotelReservation: {
                type: Object,
                default: null,
            },
            selectedTicket: {
                type: Object,
                default: null
            },
            ticketsQuantity: {
                type: Number,
                default: 2
            },
            eventTabs: {
                type: Object,
                required: true
            }
        }
    };
</script>
