<template>
    <div class="gst-review-purchase-modal-footer u-width-100">
        <EventTotalsContainer
            :title="title"
            :event="event"
            :bundle-products="bundleProducts"
            hide-price-breakdown />
    </div>
</template>

<script>
    import EventTotalsContainer from '../../_components/EventTotalsContainer.vue';

    export default {
        name: 'EventReviewPurchaseModalFooter',
        components: {
            EventTotalsContainer
        },
        props: {
            title: {
                type: String,
                default: null
            },
            bundleProducts: {
                type: Object,
                default: null
            },
            event: {
                type: Object,
                required: true
            }
        }
    };
</script>

<style lang="scss" scoped>
@import "@scssVariables";
@import "@scssMixins";

.gst-review-purchase-modal-footer {
    .gst-event-totals-container {
        border-radius: border-radius( 'xs' );
    }
}

@include mobile-only {
    .gst-review-purchase-modal-footer {
        .gst-event-totals-container {
            border-radius: border-radius( 'none' );
        }
    }
}

</style>